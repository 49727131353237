<template>
	<span>
		<v-btn class="ma-2" @click="copy" icon :small="small">
			<v-icon>mdi-content-copy</v-icon>
		</v-btn>
	</span>
</template>

<style lang="less">
	
</style>

<script type="text/javascript">
	export default {
		name: "CopyButton", 
		props: {
			text: {},
			small: Boolean
		},
		data: () => {
			return {
				copied: false
			}
		},
		methods: {
			copy(){ 
				this.copied = true;
				navigator.clipboard.writeText(this.text);
				this.$root.$emit("showToast", "Copied");
			}
		}

	}
// </script>"